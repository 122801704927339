
<template>
    <div class="px-3 " >
        <div class="row pt-0 mt-0 ">

            <!-- <div class="p-0 card-deck mb-2 mb-2 pl-2" style="" v-for="itemGroup of dataGroupV2" :key="itemGroup.groupId"
                @click="itemGroupGen = itemGroup.subGrupo;group=itemGroup.fila"> -->
            <div class="p-0 card-deck mb-2 mb-2 pl-2" style="" v-for="itemGroup of dataGroupV2" :key="itemGroup.groupId"
                @click="seleccionarCategoria(itemGroup)">
                <div class="card bg-dark p-2" style="width: 6.5rem; height: 6.5rem; border: solid 1px transparent; border-radius: 5px;" data-aos-easing="ease-in-sine" :data-aos-duration="900" :data-aos-offset="300">
                    <div class="text-center  ">
                        <img class="text-center  m-0 zoom filter" style="
                            width: 50px;
                            
                            padding: 5px;
                            border-radius: 10px;
                            " :src="itemGroup.icono" alt="" />

                    </div>
                    <input hidden :value="seccionSeleccionada" />
                    <!-- {{ itemGroup.groupId +'/'+ group +'/'+recibido }} -->
                    <p class="text-center h6">{{ itemGroup.titulo}}</p>
                </div>
            </div>
        </div>
        <!-- <div class="row" data-aos="zoom-in" data-aos-duration="500" v-if="itemGroupGen.length>0 && group == recibido">
            <div class="col-4" v-for="subGrupoItem of itemGroupGen" :key="subGrupoItem.groupId">
                <div :class="''" data-aos-easing="ease-in-sine" :data-aos-duration="900" :data-aos-offset="300">
                    <div class="text-center m-2 ">
                        <img class="text-center p-1 m-0 zoom filter" style="
                                width: 40px;
                                border: 2px solid gray;
                                padding: 10px;
                                border-radius: 10px;
                            " :src="subGrupoItem.icono" alt="" />

                    </div>
                    <p class="text-center h6">{{ subGrupoItem.titulo }}</p>
                </div>
            </div>
        </div> -->
        <!-- <div class="row" data-aos="zoom-in" data-aos-duration="500" v-if="itemGroupGen.length>0 && group == recibido">
            <div class="col-4" v-for="subGrupoItem of itemGroupGen" :key="subGrupoItem.groupId">
                <div :class="''" data-aos-easing="ease-in-sine" :data-aos-duration="900" :data-aos-offset="300">
                    <div class="text-center m-2 ">
                        <img class="text-center p-1 m-0 zoom filter" style="
                                width: 40px;
                                border: 2px solid gray;
                                padding: 10px;
                                border-radius: 10px;
                            " :src="subGrupoItem.icono" alt="" />

                    </div>
                    <p class="text-center h6">{{ subGrupoItem.titulo }}</p>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { eventBus } from "../../main";


export default {

    props: ['dataGroupV2', 'items'],
    //   name: "HeaderStyle10",
    //   components: { modal, HorizontalScroll, IconChatIA },
    // components: { Fullscreen, modal, HorizontalScroll },
    data() {
        return {
            itemGroupGen: [],
            group:null,
            recibido: null
            // subGrupoItem:subGrupoItem,

        };
    },
    async mounted() {

    },
    // destroyed(){
    //   alert('me voy....')
    // },
    // computed: {
    //     seccionSeleccionada() {
    //       console.log('ini seccionSeleccionada')
    //         let resulta = eventBus.$on("seccionSeleccionada", (data) => {
    //           this.recibido = data
    //             console.log('seccionSeleccionada ', data)
    //         })
    //         return resulta
    //     }
    // },
    methods: {
        seleccionarCategoria(item){
            console.log('click seleccionarcategorir...', item)
            localStorage.setItem('menuseleccionado', JSON.stringify(item))
            eventBus.$emit("seleccionarcategoria", item);
        },  
    },
};
</script>
<style>
/* .bg-option-sidebar{
  background-color: #9f9cd16e;
}
.dark .bg-option-sidebar{
  background-color: #0c1a30;
} */
</style>