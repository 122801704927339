<template>
  <div class="header-style-4 mx-0 pb-0 px-0">
    <div class="row px-3">
      <div class="iq-top-navbar fixed-menu col-xl-12 col-md-12 col-sm-6 offset-xl-0 text-center responsive-xl-md  p-2  pb-0" style="border: solid 1px black;" data-aos="fade-down">
        <div class="container-fluid p-0">
          <div class="iq-navbar-custom d-flex align-items-center justify-content-between">
            <div class="iq-navbar-logo d-flex align-items-center justify-content-between">
              <router-link to="" class="header-logo">
                <a class="h3 pl-3 pt-2" style="color:white !important;"   href="">Area 3 design</a>
                <!-- <img src="/img/HXLogoWhite.png" class="img-fluid rounded-normal " alt="logo" style="width: 100%; " /> -->
                <!-- <img src="/img/HXLogoBlack.png" class="img-fluid rounded-normal display-light" alt="logo" style="width: 90%" /> -->
              </router-link>
            </div>
            <div class="" v-if="visibleItems">
              <div class="row mx-0 px-0">
                <div v-for="dataItem of dataGroupFiltered?.items" :key="dataItem.items">
                   
                      <div v-if="dataItem.nivel.includes(usuario.rol.nivel)" class="text-center" :key="dataItem.id" style="width: 100%">
                        <vs-tooltip bottom danger>
                          <div class="d-flex justify-content-center">
                            <vs-button gradient floating animation-type="vertical"   icon @click="$router.push(dataItem.path)" class="mb-1 text-center my-0 " style="">
                            <!-- <vs-button relief animation-type="vertical"  danger icon @click="localStorage.setItem('opcionactual', dataItem.id);$router.push(dataItem.path);  vistaId=dataItem.id" class="mb-1 text-center my-0 " style=""> -->
                              <span class="text-white"  style="font-size: 0.9rem">{{ dataItem.title.length>10 ? (dataItem.title.substring(0,7))+'...' : dataItem.title }}</span>
                              <!-- <template #animate >
                               <img class="img-fluid text-center p-0 m-0 filter my-0 py-0" style="width: 1.5rem;"  :src="dataItem.icon" alt="" />

                              </template> -->
                              <!-- <img class="img-fluid text-center p-0 m-0 filter my-0 py-0" :src="dataItem.icon" alt="" /> -->
                              <br />
                            </vs-button>
                          </div>
                          <i v-if="vistaId == dataItem.id" style="font-size: 0.5rem;" class="fa-solid fa-circle text-center py-0 my-0"></i>
                          <template @click="$router.push(dataItem.path)" #tooltip>
                            {{ dataItem.title != "Competencias" ? dataItem.title : empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}
                          </template>
                          
                          <!-- <span @click="$router.push(dataItem.path)" class="text-center" style="font-size: 0.8rem; color: white;">
                            <span style="color: white;">
                            {{ dataItem.title != "Competencias" ? dataItem.title : empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}

                            </span>
                          </span> -->
                        </vs-tooltip>
                      </div>
                    
                  </div>
                </div>
              <!-- <vs-tooltip>
                <div class="row mx-0 px-0">
                  <div v-for="dataItem of dataGroupFiltered?.items">
                    <div v-if="dataItem.nivel.includes(usuario.rol.nivel)" class="text-center" :key="dataItem.id" style="width: 100px">
                      <vs-button success icon @click="$router.push(dataItem.path)" class="mb-1 text-center" style="width: 8vh">
                        <img class="img-fluid text-center p-0 m-0  filter" :src="dataItem.icon" alt=""  />
                        <br />
                      </vs-button>
                      <template #tooltip>
                        <span @click="$router.push(dataItem.path)" class="text-center" style="font-size: 0.8rem; color: white;">
                          <span style=" color: white;"> {{ dataItem.title != "Competencias" ? dataItem.title : empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}</span>
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </vs-tooltip> -->
            </div>
            <nav class="navbar navbar-expand-lg navbar-light px-0 mx-0">
              <div class="row">
                <div class="col-xl-12 col-md-12">
                  <ul class="navbar-nav ml-auto navbar-list align-items-center">
                    <li class="nav-item nav-icon search-content" v-nav-toggle>
                      <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-search-line"></i>
                      </a>
                      <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                        <form action="#" class="searchbox px-2">
                          <div class="form-group mb-0 position-relative">
                            <input type="text" class="text search-input" placeholder="type here to search..." />
                            <a href="#" class="search-link"><i class="ri-search-line"></i></a>
                          </div>
                        </form>
                      </div>
                    </li>
                    <!-- canjes and market -->
                    <!-- <li class="caption-content mr-3" v-sidebar-toggle @click="$router.push('/helex/canjes')">
                      <i class="fas fa-regular fa-store zoom" style="font-size: 2.5rem"></i>
                      <vs-button size="small" dark class="position-absolute px-1" style="right: 25px !important; top: -20px">
                        <b>{{ totalCoins }}</b>
                        <img style="width: 20px" loading="lazy" src="/img/coins.png" alt="coins"
                      /></vs-button>
                    </li> -->

                    <!-- notificaciones -->
                    <!-- <li class="caption-content mr-1" v-sidebar-toggle @click="$router.push('/helex/Tickets')">
                      <i class="fas fa-regular fa-headset zoom" style="font-size: 2rem; color: white;"></i>
                      <vs-button circle relief danger v-if="listaTickets.length > 0" class="position-absolute" style="left: 15px !important; top: -15px; width:1.5rem;height:1.5rem;">{{
                        listaTickets.length
                      }}</vs-button>

                     
                    </li> -->

                    <!-- <Fullscreen /> -->
                    <!-- <li class="icon-item">
                      <IconChatIA @click="$store.commit('showChatIA', true)" style="height: 40px; width: 40px; fill: var(--text-progress)" />
                    </li> -->

                    <!--  -->
                    <li class="caption-content ml-5" v-sidebar-toggle >
                      <vs-button gradient style="width: 6vh" icon success @click="showModal()">
                        <!-- <a href="#openModal"> -->
                          <img src="../../../../assets/images/menu.png" class="img-fluid zoom  pr-0 mr-0" style="" alt="" />
                          <!-- <img src="../../../../assets/images/Menu-hamburguesa-l.png" class="img-fluid zoom display-light" style="max-width: 50%" alt="" /> -->
                        <!-- </a> -->
                      </vs-button>
                    </li>
                    <span class="caption-content"  v-sidebar-toggle @click="$router.push('/')">
                      <vs-button gradient style="width: 6vh" class="" icon success>
                        <img src="../../../../assets/images/salida.png" class="img-fluid zoom  pr-0 mr-0" style="" alt="" />
                        <!-- <img src="../../../../assets/images/boton-on-off-l.png" class="img-fluid zoom display-light" style="max-width: 50%" alt="" /> -->
                      </vs-button>
                    </span>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="responsive-sm">
      <div class="pt-4" style="background-color: #102444">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-8 text-center">
            <router-link to="/dashboard" class="text-center mt-0 pt-0">
              <img src="/img/HXLogoWhite.png" class="rounded-normal mt-0 pt-0" alt="logo" style="max-width: 200px !important" />
            </router-link>
          </div>
          <div class="col-1 text-center mx-0 px-0">
            <span @click="showModal()">
              <img src="../../../../assets/images/Menu-hamburguesa.png" class="zoom pl-0" style="max-width: 40px !important" alt="" />
            </span>
          </div>
        </div>
        <div id="app" v-if="visibleItems">
          <div class="px-3 pb-3 pt-3">
            <horizontal-scroll class="pb-2" style="white-space: nowrap">
              <template v-for="dataItem of dataGroupFiltered?.items">
                <span v-if="dataItem.nivel.includes(usuario.rol.nivel)" class="text-center btn btn-dark mx-1 px-2 " :key="dataItem.id">
                  <b @click="$router.push(dataItem.path)" style="font-size: 0.9rem">{{ dataItem.title }}</b>
                </span>
              </template>
            </horizontal-scroll>
          </div>
        </div>
      </div>
      <div class=""></div>
    </div>
    <modal />
    
    <b-sidebar id="sidebar-right" backdrop-variant="transparent" backdrop style="background-color: white !important;" class="" right shadow no-header>
      <h4 class="mb-3" ><b><i class="fa-solid fa-folder-open  pr-1 text-primary pt-4 ml-3"></i> CATEGORIAS </b></h4>
     
      <div @click="enviarSeccion(0)" class="mt-3">
        <sidebarGroup :dataGroupV2="dataGroupV2.data.filter(x => x.groupId < 3)" ></sidebarGroup>
      </div>
      <div @click="enviarSeccion(1)">
        <sidebarGroup :dataGroupV2="dataGroupV2.data.filter(x => x.groupId>2 && x.groupId < 6)" ></sidebarGroup>
      </div>
      
      <!-- <div class="row" >
        
        <div style="width: 33%;" v-for="itemGroup of dataGroupV2.data.filter(x => x.groupId < 3)" :key="itemGroup.groupId" @click="itemGroupGen = itemGroup.items">
          <div :class="''" data-aos-easing="ease-in-sine" :data-aos-duration="900" :data-aos-offset="300">
            <div class="text-center m-2 ">
              <img class="text-center p-1 m-0 zoom filter" style="
                        width: 40px;
                        border: 2px solid gray;
                        padding: 10px;
                        border-radius: 10px;
                      " :src="itemGroup.icon" alt="" />
              
            </div>
            <p class="text-center h6">{{itemGroup.title }}</p>
          </div>
        </div>
      </div>
      <div class="row"  data-aos="zoom-in" data-aos-duration="500" v-if="itemGroupGen">
        <div class="col-4" v-for="subGroup of itemGroupGen" :key="subGroup.groupId">
          <div :class="''" data-aos-easing="ease-in-sine" :data-aos-duration="900" :data-aos-offset="300">
            <div class="text-center m-2 ">
              <img class="text-center p-1 m-0 zoom filter" style="
                        width: 40px;
                        border: 2px solid gray;
                        padding: 10px;
                        border-radius: 10px;
                      " :src="subGroup.icon" alt="" />
              
            </div>
            <p class="text-center h6">{{subGroup.title }}</p>
          </div>
        </div>
      </div> -->
    </b-sidebar>


    <!-- <div class="bg-pay-horizontal">
         <div class="container">           
            <div class="white-bg-menu fixed">
               <div class="iq-menu-horizontal">
                  <nav class="iq-sidebar-menu" id="5">
                    
                     <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                           <router-link to="/" class="header-logo">
                              <img :src="sidelogo" class="img-fluid rounded-normal" alt="logo">
                           </router-link> 
                           <div class="iq-menu-bt-sidebar">
                              <i class="las la-bars wrapper-menu"></i>
                           </div>
                     </div>
                     <HoverMenu :items="sidebarItems" :sidebarGroupTitle="sidebarGroupTitle" className="iq-menu d-flex mn-center" :enableCustomIcon="true" />
                  </nav>
               </div>
            </div>
         </div>
      </div> -->
  </div>
</template>
<script>
import { eventBus } from "../../../../main";

import sidebarGroup from '@/components/helexium/sidebarGroup.vue';
import dataGroupV2 from "./groupsV2.json";
// import Fullscreen from "../../../fullscreen/Fullscreen";
import sideBarItem from "@/JsonData/header.json";
import { core } from "../../../../config/pluginInit";
import { mapGetters, mapMutations } from "vuex";
import modal from "../../../helexium/modalHome.vue";
import dataGroup from "./groups.json";
// import IconChatIA from "@/components/assistant/IconChatIA.vue";

// import ModeSwitch from    "../../../../components/mode/ModeSwitch.vue";

import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
// import { event } from "jquery";
//../../../components/helexium/modalHome.vue"

export default {
  props: ["visibleItems"],
  sockets: {
    connect: function () {
      console.log("conectado a cockets. en app.vue***************************************************************");
    },
    nuevo_mensaje: async function (data) {
      let usuario = JSON.parse(localStorage.getItem("setPersonaAct")) || null;

      console.log(data);
      if (usuario != null) {
        //if (data.ParaId == usuario.id || (data.ParaId == null && usuario.rol.nivel == 4)) {
        this.getData();
        //}
      }
    },
  },
  name: "HeaderStyle10",
  components: { modal, HorizontalScroll,  sidebarGroup },
  // components: { Fullscreen, modal, HorizontalScroll },
  data() {
    return {
      // subGroup:subGroup,
      vistaId:null,
      itemGroupGen:[],
      itemSeleccionado: null,
      dataGroupV2: dataGroupV2,
      sidebarItems: sideBarItem,
      sidebarGroupTitle: true,
      dataGroup: dataGroup,
      group: 0,
      dataGroupFiltered: {},
      usuario: {},
      listaTickets: [],
      dark: null,
      progresoHojaDeVida: 0,
      personaUbicacion: {
        area: {
          id: null,
        },
      },
      listRangosEvaluaciones: {
        empresaId: null,
        nombre_empresa: null,
        evaluadoId: null,
        nombreEvaluado: null,
        r_50_59: 0,
        r_60_74: 0,
        r_75_84: 0,
        r_85_94: 0,
        r_95_100: 0,
      },
      listCanjes: [
        {
          id: null,
          beneficioId: null,
          beneficio: null,
          personaId: null,
          areaId: null,
          categoria: null,
          coins: null,
          fecha: null,
        },
      ],
      empresa: {},
      listTask: [
        {
          id: null,
          collaboratorId: null,
          collaborator: null,
          personaId: null,
          legoCardId: null,
          legoCard: {
            effort: 0,
          },
          points: null,
          acceptTask: null,
        },
      ],
    };
  },
  beforeCreate(){
    this.vistaActual = localStorage.getItem("opcionactual")
  },
  async mounted() {
    
    // await this.obtenerVistaActual()
    let { empresa } = JSON.parse(localStorage.setPersonaAct);
    this.empresa = empresa;
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    // console.log(this.usuario);
    this.listCanjes = [];
    this.listTask = [];
    await this.getProgresoHojaDeVida(this.usuario.id);
    await this.getPersonaUbicacion(this.usuario.id);
    await this.getConfigCoinsCanjesByEmpresa(this.usuario.empresa.id, this.personaUbicacion.area.id);
    await this.getCanjesLog();
    await this.getListRangosEvaluacionesPersona();
    await this.getListLegoAssignmentResponsibilityByPersona();

    this.handleCoins();

    // console.log(this.totalCoins);
    // console.log(this.configCoinsCanjes);
    await this.getData();
    this.$store.watch(
      (state) => [state.group, state.newChat].join(),
      () => {
        //console('***************************',this.$store.state.group)
        this.dataGroupFiltered = this.dataGroup.data[this.$store.state.group - 1]; // this.dataGroup.data[this.group];
        // this.modalShowLocal = this.$store.state.modalShow;
        //console("actualizando modalshowlocal en modalhome", this.dataGroup);
        // console(
        //   "actualizando modalshowlocal en modalhome",
        //   this.dataGroupFiltered
        // );
        this.getData();
      }
    );

    this.dataGroupFiltered = this.dataGroup.data[this.$store.state.group - 1] || this.dataGroup.data[localStorage.getItem("group") - 1]; // this.dataGroup.data[this.group];
    // console("datagroup ", this.dataGroupFiltered);
    // alert(this.dark)

    core.changesidebar();
  },
  computed: {
    ...mapGetters({
      appName: "appName",
      // logo: "~/img/HXWhite.png",
      sidelogo: "logo",
      name: "name",
      image1: "image1",
      getTotalCanjes: "getTotalCanjes",
      getTotalCoins: "getTotalCoins",
      getIsAvatar: "getIsAvatar",
      getIsOnboarding: "getIsOnboarding",
      getGananciasCoinsTasks: "getGananciasCoinsTasks",
      getGananciasCoinsRangoEvaluaciones: "getGananciasCoinsRangoEvaluaciones",
      getConfigCoinsCanjes: "getConfigCoinsCanjes",
    }),
    configCoinsCanjes() {
      return this.getConfigCoinsCanjes;
    },
    totalCoins() {
      return this.getTotalCoins;
    },
    totalCanjes() {
      return this.getTotalCanjes;
    },
    isOnboarding() {
      return this.getIsOnboarding;
    },
    isAvatar() {
      return this.getIsAvatar;
    },
    tasksCoins() {
      return this.getGananciasCoinsTasks;
    },
    rangoEvaluacionesCoins() {
      return this.getGananciasCoinsRangoEvaluaciones;
    },
  },
  methods: {
    // obtenerVistaActual(){
    //   console.log('*********************************', this.$route.path.split("/"))
    //   let vistaActual = this.$route.path.split("/")
    //   vistaActual = vistaActual[vistaActual.length-1]
    //   console.log('*********************************', vistaActual)
    // },
    enviarSeccion(id){
      console.log('enviarseccion', id)
      eventBus.$emit("seccionSeleccionada", id);
    },
    ...mapMutations({
      setTotalCoins: "setTotalCoins",
      setTotalCanjes: "setTotalCanjes",
      setIsAvatar: "setIsAvatar",
      setIsOnboarding: "setIsOnboarding",
      setIsCurriculum: "setIsCurriculum",
      setGananciasCoinsTasks: "setGananciasCoinsTasks",
      setGananciasCoinsRangoEvaluaciones: "setGananciasCoinsRangoEvaluaciones",
      setConfigCoinsCanjes: "setConfigCoinsCanjes",
    }),
    updateConfigCoinsCanjes(newValue) {
      this.setConfigCoinsCanjes(newValue);
    },
    updateTotalCoins(newValue) {
      this.setTotalCoins(newValue);
    },
    updateTotalCanjes(newValue) {
      this.setTotalCanjes(newValue);
    },
    updateIsAvatar(newValue) {
      this.setIsAvatar(newValue);
    },
    updateIsOnboarding(newValue) {
      this.setIsOnboarding(newValue);
    },
    updateIsCurriculum(newValue) {
      this.setIsCurriculum(newValue);
    },
    updateTasksCoins(newValue) {
      this.setGananciasCoinsTasks(newValue);
    },
    updateRangoEvaluacionesCoins(newValue) {
      this.setGananciasCoinsRangoEvaluaciones(newValue);
    },
    handleCoins() {
      let canjes = this.listCanjes.reduce((TotalCanje, canje) => TotalCanje + parseInt(canje.coins), 0);
      this.updateTotalCanjes(canjes);

      this.updateTotalCoins(this.handleGanancias() - canjes);
    },
    handleGanancias() {
      let total = 0;

      // avatar
      // console.log("avatar", this.usuario.sharedCoins);

      if (this.usuario.sharedCoins !== null) {
        total += this.usuario.sharedCoins;
      }

      // avatar
      if (this.usuario.avatar !== null && this.usuario.stringAvatar !== null) {
        total += this.configCoinsCanjes.avatar;
        this.updateIsAvatar(true);
      }
      // onboarding
      if (this.isOnboarding) {
        total += this.configCoinsCanjes.onboarding;
        // this.updateIsOnboarding(true);
      }

      // curriculum
      if (this.progresoHojaDeVida === 100) {
        total += this.configCoinsCanjes.curriculum;
        this.updateIsCurriculum(true);
      }

      // tareas
      if (this.listTask.length > 0) {
        // reset data tasks
        this.updateTasksCoins({
          basico: {
            completed: 0,
            pendients: 0,
          },
          medio: {
            completed: 0,
            pendients: 0,
          },
          alto: {
            completed: 0,
            pendients: 0,
          },
        });

        let { basico, medio, alto } = this.tasksCoins;

        // console.log(this.listTask);

        // primos para estimar esfuerzo
        // [0, 1,2,3,5,7,11,13,17,19,23,29,31,37,41,43,47],

        this.listTask.forEach((task) => {
          let taskLevel;
          // eslint-disable-next-line no-unused-vars
          let pointsMultiplier;

          // Determinar el nivel de la tarea
          if (task.legoCard.effort >= 31) {
            taskLevel = alto;
            pointsMultiplier = this.configCoinsCanjes.tareaAlta;
          } else if (task.legoCard.effort >= 11 && task.legoCard.effort < 31) {
            taskLevel = medio;
            pointsMultiplier = this.configCoinsCanjes.tareaMedia;
          } else {
            taskLevel = basico;
            pointsMultiplier = this.configCoinsCanjes.tareaBasica;
          }

          // Incrementar completadas o pendientes según corresponda
          if (task.acceptTask !== null) {
            taskLevel.completed++;
            // Calcular puntos y actualizar total
            if (task.points === 0) {
              total += pointsMultiplier;
            } else if (task.points > 0) {
              total += pointsMultiplier * task.points + pointsMultiplier;
            } else {
              total -= Math.abs(task.points);
            }
          } else {
            taskLevel.pendients++;
          }
        });

        const newListTask = {
          basico,
          medio,
          alto,
        };

        // console.log(basico);

        // console.log("newListTask---------->", medio);

        this.updateTasksCoins(newListTask);
      }
      // evaluaciones

      if (this.listRangosEvaluaciones.empresaId !== null) {
        const { r_50_59, r_60_74, r_75_84, r_85_94, r_95_100 } = this.listRangosEvaluaciones;

        let newDataRanges = {
          ...this.getGananciasCoinsRangoEvaluaciones,
          r_50_59: {
            cant: r_50_59,
            ganTotal: this.configCoinsCanjes.rango_50_59 * r_50_59,
          },
          r_60_74: {
            cant: r_60_74,
            ganTotal: this.configCoinsCanjes.rango_60_74 * r_60_74,
          },
          r_75_84: {
            cant: r_75_84,
            ganTotal: this.configCoinsCanjes.rango_75_84 * r_75_84,
          },
          r_85_94: {
            cant: r_85_94,
            ganTotal: this.configCoinsCanjes.rango_85_94 * r_85_94,
          },
          r_95_100: {
            cant: r_95_100,
            ganTotal: this.configCoinsCanjes.rango_95_100 * r_95_100,
          },
        };

        // console.log("newDataRanges", newDataRanges);
        this.updateRangoEvaluacionesCoins(newDataRanges);

        if (r_50_59 !== 0) {
          total += this.configCoinsCanjes.rango_50_59 * r_50_59;
        }
        if (r_60_74 !== 0) {
          total += this.configCoinsCanjes.rango_60_74 * r_60_74;
        }
        if (r_75_84 !== 0) {
          total += this.configCoinsCanjes.rango_75_84 * r_75_84;
        }
        if (r_85_94 !== 0) {
          total += this.configCoinsCanjes.rango_85_94 * r_85_94;
        }
        if (r_95_100 !== 0) {
          total += this.configCoinsCanjes.rango_95_100 * r_95_100;
        }
      }

      // console.log(total);

      return total;
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        // console.log("persona ubicacion...", res);
        if (res.cargo) {
          this.personaUbicacion = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getProgresoHojaDeVida(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `HojaDeVidaProgreso/${personaId}`,
        });
        // console.log("progresoHojaDeVida------------>", res);
        if (res !== null) {
          this.progresoHojaDeVida = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListRangosEvaluacionesPersona() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "EstadisticasEvaluacionesEmpresaV2/ListRangosEvaluacionesPersona/" + this.usuario.id,
        });
        // console.log("list rangos evaluaciones persona...", res);
        if (res.success && res.data.length > 0) {
          const cantidadesPorRango = {
            r_50_59: 0,
            r_60_74: 0,
            r_75_84: 0,
            r_85_94: 0,
            r_95_100: 0,
          };

          res.data.forEach((evaluacion) => {
            if (evaluacion.rango) {
              if (cantidadesPorRango[evaluacion.rango]) {
                cantidadesPorRango[evaluacion.rango] += evaluacion.cantidad;
              } else {
                cantidadesPorRango[evaluacion.rango] = evaluacion.cantidad;
              }
            }
          });

          // console.log(cantidadesPorRango);

          this.listRangosEvaluaciones = {
            empresaId: res.data[0].empresaId,
            nombre_empresa: res.data[0].nombre_empresa,
            evaluadoId: res.data[0].evaluadoId,
            nombreEvaluado: res.data[0].nombreEvaluado,
            ...cantidadesPorRango,
          };

          // console.log(this.listRangosEvaluaciones);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getGeneralConfigCoinsCanjesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ConfigCoinsCanjes/GetGeneralByEmpresa/${empresaId}`,
        });
        // console.log("general ConfigCoinsCanjes...", res);
        if (res.success && res.data !== null) {
          this.updateConfigCoinsCanjes({
            ...res.data,
            areaIds: res.data.areaIds !== null ? res.data.areaIds.split(",") : [],
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getConfigCoinsCanjesByEmpresa(empresaId, areaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ConfigCoinsCanjes/GetByEmpresa/${empresaId}/${areaId}`,
        });
        // console.log("ConfigCoinsCanjes...", res);
        if (res.success && res.data !== null) {
          this.updateConfigCoinsCanjes({
            ...res.data,
            areaIds: res.data.areaIds !== null ? res.data.areaIds.split(",") : [],
          });
        } else {
          this.getGeneralConfigCoinsCanjesByEmpresa(empresaId);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListLegoAssignmentResponsibilityByPersona() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Lego/ListLegoAssignmentResponsibilityByPersona/" + this.usuario.id,
        });
        // console.log("ListLegoAssignmentResponsibilityByPersona...", res);
        if (res.length > 0) {
          this.listTask = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getCanjesLog() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CanjesMarketLog/ListByPersona/" + this.usuario.id,
        });
        // console.log("list canjes persona...", res);
        if (res.success) {
          this.listCanjes = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    // changeMode() {
    //   let dark = localStorage.getItem("modoseleccionado") == true || localStorage.getItem("modoseleccionado") == "true" ? true : false;
    //   const body = document.querySelector("body");
    //   if (dark) {
    //     body.classList.add("dark");
    //     localStorage.setItem("modoseleccionado", true);
    //   } else {
    //     body.classList.remove("dark");
    //     localStorage.setItem("modoseleccionado", false);
    //   }
    // },
    showModal() {
      //this.$store.commit('modalShowRefresh', false)
      // console.log('modalShowRefresh', this.$store.state.modalShow)
      this.$store.commit("modalShowRefresh", true);
      //console.log('modalShowRefresh', this.$store.state.modalShow)
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaTicket/GetOpenTickets",
        });
        // console.log("tickets... ", res);
        if (res.length > 0) {
          if (this.usuario.rol.nivel == 4) {
            this.listaTickets = res.filter((x) => (x.paraId == null || x.paraId == this.usuario.id) && x.estado == 1);
          } else {
            this.listaTickets = res.filter((x) => x.paraId == this.usuario.id && x.estado == 1);
          }
        } else {
          this.listaTickets = [];
        }
      } catch (error) {
        this.listaTickets = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
.icon-item {
  margin-left: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
}
.icon-item:hover {
  transform: scale(1.1);
}
</style>
