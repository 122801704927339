var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modalShowLocal == true)?_c('div',{staticClass:"modalDialog contenedor-modal"},[_c('div',{staticClass:"text-white responsive-xl-md",attrs:{"data-aos":"zoom-in"}},[_c('a',{staticClass:"close_x text-white contenedor-modal zoom",attrs:{"title":"Close"},on:{"click":function($event){return _vm.$store.commit('modalShowRefresh', false)}}},[_vm._m(0)]),_c('div',{staticClass:"row contenedor-modal"},[_c('div',{staticClass:"col-xl-12",attrs:{"data-aos":"zoom-in","data-aos-duration":"900"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.dataGroup),function(itemGroup){return [(itemGroup.nivel.includes(_vm.usuario.rol.nivel))?_c('div',{key:itemGroup.id,class:_vm.usuario.rol.nivel == 5 ? 'col' : 'col-4',attrs:{"data-aos-easing":"ease-in-sine","data-aos-duration":900,"data-aos-offset":300}},[_c('div',{staticClass:"d-flex justify-content-center"},[(itemGroup.id == 14)?_c('vs-button',{staticClass:"text-center ml-3 mr-3 mb-2 mt-2",attrs:{"gradient":""},on:{"click":function($event){_vm.$bvModal.show('modalMapaConductual');
                  _vm.$store.commit('groupRefresh', itemGroup.id);}}},[_c('img',{staticClass:"text-center p-1 m-0 zoom filter",attrs:{"src":itemGroup.icon,"alt":""}})]):(itemGroup.id != 14 && itemGroup.id != 21)?_c('vs-button',{staticClass:"text-center m-2",staticStyle:{"width":"5rem"},attrs:{"gradient":""},on:{"click":function($event){_vm.$store.commit('groupRefresh', itemGroup.id);
                  _vm.$store.commit('modalShowRefresh', false);
                  _vm.$router.push(itemGroup.path);}}},[_c('img',{staticClass:"text-center p-1 m-0 zoom filter",attrs:{"src":itemGroup.icon,"alt":""}}),(
                    [7, 9, 19].includes(itemGroup.id) &&
                    _vm.getPendingCount(itemGroup.id) > 0
                  )?_c('b-badge',{staticClass:"position-absolute"},[_vm._v(_vm._s(_vm.getPendingCount(itemGroup.id)))]):_vm._e()],1):_vm._e()],1),(itemGroup.id != 21)?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(itemGroup.group)+" ")]):_vm._e()]):_vm._e()]}),_c('modalMapaConductual_component')],2)])])]),_c('div',{staticClass:"text-white responsive-sm",attrs:{"data-aos":"zoom-in"}},[_c('a',{staticClass:"close_x text-white contenedor-modal zoom",attrs:{"title":"Close"},on:{"click":function($event){return _vm.$store.commit('modalShowRefresh', false)}}},[_vm._m(1)]),_c('div',{staticClass:"row contenedor-modal p-4"},[_c('div',{staticClass:"col-xl-12",attrs:{"data-aos":"zoom-in","data-aos-duration":"900"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.dataGroup),function(itemGroup){return [(itemGroup.nivel.includes(_vm.usuario.rol.nivel))?_c('div',{key:itemGroup.id,class:_vm.usuario.rol.nivel == 5 ? 'col' : 'col-4',attrs:{"data-aos-easing":"ease-in-sine","data-aos-duration":900,"data-aos-offset":300}},[(itemGroup.id == 19)?_c('div',{staticClass:"text-center m-3"},[_c('a',{attrs:{"href":"https://helexium-lego-sistema-de-gestion.vercel.app/","target":"_blank"}},[_c('img',{staticClass:"text-center p-1 m-0 zoom filter",staticStyle:{"width":"40px","border":"2px solid gray","padding":"10px","border-radius":"10px"},attrs:{"src":itemGroup.icon,"alt":""}})])]):(itemGroup.id == 21)?_c('div',{staticClass:"text-center m-3",on:{"click":function($event){_vm.$bvModal.show('modalMapaConductual');
                  _vm.$store.commit('groupRefresh', itemGroup.id);}}},[_c('img',{staticClass:"text-center p-1 m-0 zoom filter",staticStyle:{"width":"40px","border":"2px solid gray","padding":"10px","border-radius":"10px"},attrs:{"src":itemGroup.icon,"alt":""}})]):(itemGroup.id != 19 && itemGroup.id != 21)?_c('vs-button',{staticClass:"text-center m-2",attrs:{"icon":""},on:{"click":function($event){_vm.$store.commit('groupRefresh', itemGroup.id);
                  _vm.$store.commit('modalShowRefresh', false);
                  _vm.$router.push(itemGroup.path);}}},[_c('img',{staticClass:"text-center p-1 m-0 zoom filter",staticStyle:{"width":"40px","/* border":"2px solid gray","*/\n                    padding":"10px","border-radius":"10px"},attrs:{"src":itemGroup.icon,"alt":""}}),(
                    [7, 9, 14].includes(itemGroup.id) &&
                    _vm.getPendingCount(itemGroup.id) > 0
                  )?_c('b-badge',{staticClass:"position-absolute"},[_vm._v(_vm._s(_vm.getPendingCount(itemGroup.id)))]):_vm._e()],1):_vm._e(),_c('p',{staticClass:"text-center h6"},[_vm._v(_vm._s(itemGroup.group))])],1):_vm._e()]})],2)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"margin":"auto","color":"aliceblue !important"}},[_c('i',{staticClass:"fa-solid fa-xmark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"margin":"auto"}},[_c('b',[_vm._v(" x ")])])
}]

export { render, staticRenderFns }